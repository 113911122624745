import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  connect() {
    $('#upload-files').change(function () {
      const fileInput = document.querySelector('input[type="file"]');
      const hasFiles = fileInput.files && fileInput.files.length > 0;
      const h1Text = document.querySelector('.file-name');

      if (hasFiles) {
        // no forms
        const filename = document.querySelector('input[type="file"]').files[0].name;
        h1Text.innerText = filename;

        $('.input-file-supplementary-text').addClass('d-none');
        $('.input-file-icon-circle').removeClass('d-none');

        //change background
        $('.input-file-component').css('background-color', '#E6F6FD');
      }
    });

    // drag document
    const inputFileComponent = document.querySelector('.input-file-component');

    inputFileComponent.addEventListener('dragover', function (event) {
      event.preventDefault();
      inputFileComponent.classList.add('dragover');

      $('.input-file-component').css('background-color', '#E6F6FD');
    });

    inputFileComponent.addEventListener('dragleave', function () {
      inputFileComponent.classList.remove('dragover');

      $('.input-file-component').css('background-color', 'white');
    });

    inputFileComponent.addEventListener('drop', function (event) {
      event.preventDefault();
      inputFileComponent.classList.remove('dragover');
      const files = event.dataTransfer.files;

      const h1Text = document.querySelector('.file-name');
      h1Text.innerText = `Arquivo inserido: ${files[0].name}`;

      $('.input-file-supplementary-text').addClass('d-none');
      $('.input-file-icon-circle').removeClass('d-none');
      $('.input-file-component').css('background-color', '#E6F6FD');
    });
  }

  deleteFile(event) {
    event.preventDefault();

    const fileInput = document.querySelector('input[type="file"]');
    fileInput.value = '';
    fileInput.type = 'text';
    fileInput.type = 'file';

    $('.input-file-icon-circle').addClass('d-none');
    $('.input-file-supplementary-text').removeClass('d-none');

    const h1Text = document.querySelector('.file-name');
    h1Text.innerText = 'Selecione um arquivo';

    $('.input-file-component').css('background-color', 'white');

    // Dispatch change event
    var changeEvent = new Event('change');
    fileInput.dispatchEvent(changeEvent);
  }
}
