import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    const radioButtons = this.element.querySelectorAll('input[type="radio"]');
    radioButtons.forEach((radio) => {
      radio.addEventListener('change', this.changeStyle.bind(this));
    });

    this.applyInitialStyles();
  }

  applyInitialStyles() {
    const selectedRadio = this.element.querySelector('input[type="radio"]:checked');
    if (selectedRadio) {
      this.applyStyles(selectedRadio.closest('.response__alternative--select'));
    }
  }

  changeStyle(event) {
    const select = this.element.querySelectorAll('.response__alternative--select');
    const selectBorder = this.element.querySelectorAll('.response__alternative--button-border');
    const selectAlternativeBorder = this.element.querySelectorAll('.response__alternative--border');

    select.forEach((element) => {
      element.classList.add('tw-bg-white');
      element.classList.add('hover:tw-bg-coolGray-50');
      element.classList.remove('tw-border-primary-100');
    });

    selectBorder.forEach((element) => {
      element.classList.remove('tw-border-primary-100');
      element.classList.add('tw-border-coolGray-400');
    });

    selectAlternativeBorder.forEach((element) => {
      element.classList.remove('tw-border-primary-100');
      element.classList.remove('tw-text-primary-500');
      element.classList.add('tw-text-coolGray-600');
      element.classList.add('tw-border-coolGray-400');
    });

    this.applyStyles(event.target.closest('.response__alternative--select'));
  }

  applyStyles(selectedElement) {
    const selectedElementBorder = selectedElement.nextElementSibling;
    const selectedAlternativeBorder = selectedElement.querySelector(
      '.response__alternative--border',
    );

    selectedElement.classList.remove('tw-bg-white');
    selectedElement.classList.remove('hover:tw-bg-coolGray-50');
    selectedElement.classList.add('tw-bg-primary-25');
    selectedElement.classList.add('tw-border-coolGray-400');
    selectedElement.classList.add('tw-border-primary-100');

    if (
      selectedElementBorder &&
      selectedElementBorder.classList.contains('response__alternative--button-border')
    ) {
      selectedElementBorder.classList.remove('tw-border-coolGray-400');
      selectedElementBorder.classList.add('tw-border-primary-100');
    }

    if (
      selectedAlternativeBorder &&
      selectedAlternativeBorder.classList.contains('response__alternative--border')
    ) {
      selectedAlternativeBorder.classList.remove('tw-border-coolGray-400');
      selectedAlternativeBorder.classList.add('tw-border-primary-100');

      selectedAlternativeBorder.classList.remove('tw-text-coolGray-600');
      selectedAlternativeBorder.classList.add('tw-text-primary-500');
    }
  }
}
