import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['scrollArea', 'pagination'];

  initialize() {
    let options = {
      rootMargin: '200px',
    };

    this.intersectionObserver = new IntersectionObserver(
      (entries) => this.handleIntersect(entries),
      options,
    );
  }

  paginationTargetConnected() {
    this.intersectionObserver.observe(this.paginationTarget);
  }

  paginationTargetDisconnected() {
    this.intersectionObserver.unobserve(this.paginationTarget);
  }

  handleIntersect(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  loadMore() {
    const next = this.paginationTarget.querySelector('[rel=next]');
    if (!next) {
      this.intersectionObserver.unobserve(this.paginationTarget);
      return;
    }
    const href = next.href;
    fetch(href, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .catch((e) => console.log(e));
  }
}
