import { Controller as BaseController } from '@hotwired/stimulus';

export default class extends BaseController {
  static targets = ['sidebar', 'background'];

  connect() {
    // Garante que a sidebar começa fechada
    this.closeSidebar();
  }

  toggle() {
    if (this.sidebarTarget.classList.contains('tw-right-0')) {
      this.closeSidebar();
    } else {
      this.openSidebar();
    }
  }

  openSidebar() {
    this.sidebarTarget.classList.remove('tw-right-[-1000px]');
    this.sidebarTarget.classList.add('tw-right-0');

    this.backgroundTarget.classList.remove('tw-hidden');
  }

  closeSidebar() {
    this.sidebarTarget.classList.remove('tw-right-0');
    this.sidebarTarget.classList.add('tw-right-[-1000px]');

    this.backgroundTarget.classList.add('tw-hidden');
  }
}
