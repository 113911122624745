import { Controller as BaseController } from '@hotwired/stimulus';
import { useMatchMedia } from 'stimulus-use';

export default class Controller extends BaseController {
  static targets = ['dropdownTasks', 'dropdownEntity', 'dropdownReports'];

  connect() {
    useMatchMedia(this, {
      mediaQueries: {
        small: '(max-width: 576px)', // Bootstrap small device
      },
    });
  }

  isSmall() {
    this.toggle(); // closes sidebar if is small
  }

  toggle() {
    // Changes width
    $('.sidebar').toggleClass('sidebar-expanded sidebar-collapsed');

    // Hide items
    $('.sidebar-line-collapsed-hide').toggleClass('d-none');
    $('.sidebar-icon').toggleClass('fa-chevron-left fa-chevron-right');

    const sidebar = document.querySelector('.sidebar');
    const hasExpandend = sidebar.classList.contains('sidebar-expanded');

    if (hasExpandend) {
      $('.sidebar-nav-span').css('display', 'flex');
      $('.sidebar-nav-span-new').css('display', 'flex');
      $('.sidebar-collapsed-hide').css('display', 'block');
      $('.sidebar-img').removeClass('close-img-sidebar');
      $('.sidebar-close-mobile').removeClass('d-none');

      setTimeout(() => {
        $('.sidebar-nav-span').removeClass('sidebar-span-none');
        $('.sidebar-nav-span-new').removeClass('sidebar-span-none');
        $('.sidebar-collapsed-hide').removeClass('sidebar-span-none');
      }, 300);

      setTimeout(() => {
        $('.sidebar-nav-span').removeClass('sidebar-span-none');
        $('.sidebar-nav-span-new').removeClass('sidebar-span-none');
        $('.sidebar-collapsed-hide').removeClass('sidebar-span-none');
      }, 750);
    } else {
      $('.sidebar-nav-span').css('display', 'none');
      $('.sidebar-nav-span-new').css('display', 'none');
      $('.sidebar-collapsed-hide').css('display', 'none');
      $('.sidebar-img').addClass('close-img-sidebar');
      $('.sidebar-close-mobile').addClass('d-none');

      $('.sidebar-nav-span').addClass('sidebar-span-none');
      $('.sidebar-nav-span-new').addClass('sidebar-span-none');
      $('.sidebar-collapsed-hide').addClass('sidebar-span-none');

      $('.sidebar-dropdown-new').removeClass('dropdown-open-two');
      $('.sidebar-dropdown-new').removeClass('dropdown-open-five');
      $('.sidebar-dropdown-new').addClass('dropdown-close');
    }
  }

  clickLink() {
    let width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    if (width < 576) {
      this.toggle();
    }
  }

  toggleDropdownTasks(event) {
    let dropdownClass = 'dropdown-open-five';

    switch (event.params.quantity) {
      case 3:
        dropdownClass = 'dropdown-open-three';
        break;
      case 4:
        dropdownClass = 'dropdown-open-four';
        break;
    }

    if (this.dropdownTasksTarget.classList.contains('dropdown-close')) {
      this.dropdownTasksTarget.classList.replace('dropdown-close', dropdownClass);
    } else {
      this.dropdownTasksTarget.classList.replace(dropdownClass, 'dropdown-close');
    }
  }

  toggleDropdownReports() {
    if (this.dropdownReportsTarget.classList.contains('dropdown-close')) {
      this.dropdownReportsTarget.classList.replace('dropdown-close', 'dropdown-open-two');
    } else {
      this.dropdownReportsTarget.classList.replace('dropdown-open-two', 'dropdown-close');
    }
  }
}
