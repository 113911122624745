import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static targets = ['bar'];
  static values = { answered: Number, total: Number, green: Boolean };

  connect() {
    if (this.greenValue == true) {
      return;
    }
    setTimeout(() => {
      const newFractionBar = ((this.answeredValue * 100) / this.totalValue).toFixed(2);
      this.barTarget.style.width = `${newFractionBar}%`;
    }, 1000);
  }
}
