import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static targets = ['numberInputField'];
  errorClasses = ['tw-border-error-500'];
  removeErrorClasses = [
    'tw-border-coolGray-100',
    'hover:tw-border-primary-200',
    'focus:tw-border-primary-200',
  ];
  hasUpperBound = false;
  hasLowerBound = false;
  hasError = false;
  min = '';
  max = '';

  connect() {
    this.setup();
  }

  toggleError(hasError) {
    this.hasError = hasError;
    if (hasError) {
      this.numberInputFieldTarget.classList.add(...this.errorClasses);
      this.numberInputFieldTarget.classList.remove(...this.removeErrorClasses);
    } else {
      this.numberInputFieldTarget.classList.remove(...this.errorClasses);
      this.numberInputFieldTarget.classList.add(...this.removeErrorClasses);
    }
  }

  input() {
    const error = this.testError();

    if (error !== this.hasError) {
      this.toggleError(error);
    }
  }

  testError() {
    if (!this.numberInputFieldTarget.value) {
      return false;
    }

    if (this.hasLowerBound && this.numberInputFieldTarget.value < this.min) {
      return true;
    }

    if (this.hasUpperBound && this.numberInputFieldTarget.value > this.max) {
      return true;
    }

    return false;
  }

  setup() {
    const [dataMax, dataMin] = [this.numberInputFieldTarget.max, this.numberInputFieldTarget.min];

    if (dataMin && dataMin !== '') {
      this.hasLowerBound = true;
      this.min = Number(dataMin);
    }

    if (dataMax && dataMax !== '') {
      this.hasUpperBound = true;
      this.max = Number(dataMax);
    }
  }
}
