import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static targets = ['inputField'];

  connect() {
    this.checkFilled();
  }

  toggleIconClass(focused) {
    const icons = this.element.querySelectorAll('.component-input__icon');
    icons.forEach((icon) => {
      if (focused) {
        icon.classList.add('tw-text-coolGray-700');
        icon.classList.remove('tw-text-coolGray-400');
      } else {
        icon.classList.add('tw-text-coolGray-400');
        icon.classList.remove('tw-text-coolGray-700');
      }
    });

    const hiddenEyeDiv = this.element.querySelector('.component-input__password-icon--eye-closed');
    if (hiddenEyeDiv) {
      if (focused) {
        hiddenEyeDiv.classList.add('tw-text-coolGray-700');
        hiddenEyeDiv.classList.remove('tw-text-coolGray-400');
      } else {
        hiddenEyeDiv.classList.add('tw-text-coolGray-400');
        hiddenEyeDiv.classList.remove('tw-text-coolGray-700');
      }
    }
  }

  focus() {
    this.toggleIconClass(true);
  }

  hidden() {
    this.inputFieldTarget.focus();

    const showEyeDiv = this.element.querySelector('.component-input__password-icon--eye-open ');
    const hiddenEyeDiv = this.element.querySelector('.component-input__password-icon--eye-closed');

    if (showEyeDiv && hiddenEyeDiv) {
      showEyeDiv.classList.toggle('tw-hidden');
      hiddenEyeDiv.classList.toggle('tw-hidden');

      if (this.inputFieldTarget.type === 'password') {
        this.inputFieldTarget.type = 'text';
      } else {
        this.inputFieldTarget.type = 'password';
      }
    }
  }

  checkFilled() {
    if (!this.hasInputFieldTarget) return;
    if (this.inputFieldTarget === document.activeElement) return;

    const icons = this.element.querySelectorAll('.component-input__icon');
    icons.forEach((icon) => {
      if (this.inputFieldTarget.value.trim() !== '') {
        icon.classList.add('tw-text-coolGray-700');
        icon.classList.remove('tw-text-coolGray-400');
      } else {
        icon.classList.add('tw-text-coolGray-400');
        icon.classList.remove('tw-text-coolGray-700');
      }
    });

    const hiddenEyeDiv = this.element.querySelector('.component-input__password-icon--eye-closed');
    if (hiddenEyeDiv) {
      if (this.inputFieldTarget.value.trim() !== '') {
        hiddenEyeDiv.classList.add('tw-text-coolGray-700');
        hiddenEyeDiv.classList.remove('tw-text-coolGray-400');
      } else {
        hiddenEyeDiv.classList.add('tw-text-coolGray-400');
        hiddenEyeDiv.classList.remove('tw-text-coolGray-700');
      }
    }
  }

  blur() {
    this.toggleIconClass(false);
    this.checkFilled();
  }
}
