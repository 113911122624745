import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['template', 'target', 'icon'];

  add(e) {
    e.preventDefault();

    try {
      const form =
        e.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.getElementsByTagName(
          'form',
        )[0];

      const nameValue = form.elements['name'].value;
      const webUrlValue = form.elements['web_url'].value;
      const attachTypeValue = form.elements['attach_type'].value;

      if (!nameValue || !webUrlValue || !attachTypeValue) {
        alert('Todos os campos devem estar preenchidos.');
        return;
      }

      const newRecordId = new Date().getTime().toString();

      const content = this.templateTarget.innerHTML
        .replace(/NEW_RECORD/g, newRecordId)
        .replace(/NEW_TITLE/g, nameValue)
        .replace(/NEW_SUB_TITLE/g, webUrlValue)
        .replace(/NEW_TYPE/g, attachTypeValue);

      if ('content' in document.createElement('template')) {
        const template = document.createElement('template');
        template.innerHTML = content.trim();
        const clone = template.content.firstElementChild.cloneNode(true);

        const icons = clone.querySelectorAll('div[data-type]');
        icons.forEach((icon) => {
          if (icon.dataset.type === attachTypeValue.toLowerCase()) {
            icon.classList.remove('tw-hidden');
          }
        });
        clone.id = `attach-container-${newRecordId}`;
        clone.dataset.recordId = newRecordId;
        this.targetTarget.insertAdjacentElement('beforebegin', clone);
      }
    } catch (e) {
      alert('Ocorreu um erro ao tentar adicionar o anexo, por favor tente novamente.');
    }
  }

  removeAttachment(event) {
    event.preventDefault();

    const id = event.params.id;

    const target = event.target.closest('[id^="attach-container-"]');

    document.getElementById(
      `virtual_classroom_post_classroom_post_attaches_attributes_${id}__destroy`,
    ).value = true;

    target.querySelector('input[type="hidden"]').value = '1';
    target.style.display = 'none';

    const formFields = target.querySelectorAll('.form-control');
    formFields.forEach((field) => (field.disabled = true));
  }

  editWithValue(event) {
    event.preventDefault();

    const id = event.params.id;
    const target = document.getElementById(`attach-container-${id}`);

    if (!target) {
      console.error(`Elemento com ID attach-container-${id} não encontrado.`);
      return;
    }

    const nameInput = target.querySelector('input[name="name"]');
    const webUrlInput = target.querySelector('input[name="web_url"]');

    if (!nameInput || !webUrlInput) {
      console.error('Um ou mais elementos de entrada estão faltando.');
      return;
    }

    // Changes name
    const nameValue = nameInput.value;
    const nameElement = target.querySelector('p.tw-font-medium');
    if (nameElement) {
      nameElement.textContent = nameValue || 'Nome do anexo';
    }
    document.getElementById(
      `virtual_classroom_post_classroom_post_attaches_attributes_${id}_name`,
    ).value = nameValue;

    // Changes web url
    const webUrlValue = webUrlInput.value;
    const attachTypeElement = target.querySelector('p.tw-font-normal');
    if (attachTypeElement) {
      attachTypeElement.textContent = webUrlValue || 'Tipo de anexo';
    }

    document.getElementById(
      `virtual_classroom_post_classroom_post_attaches_attributes_${id}_web_url`,
    ).value = webUrlValue;
  }

  edit(event) {
    event.preventDefault();

    try {
      const target = event.target.closest('[id^="attach-container-"]');
      const recordId = target.dataset.recordId;

      const nameInput = target.querySelector('input[name="name"]');
      const webUrlInput = target.querySelector('input[name="web_url"]');
      const attachTypeInput = target.querySelector('input[name="attach_type"]');

      if (!nameInput || !webUrlInput || !attachTypeInput) {
        throw new Error('Um ou mais elementos de entrada estão faltando.');
      }

      const nameValue = nameInput.value;
      const webUrlValue = webUrlInput.value;
      const attachTypeValue = attachTypeInput.value;

      const content = this.templateTarget.innerHTML
        .replace(/NEW_RECORD/g, recordId)
        .replace(/NEW_TITLE/g, nameValue)
        .replace(/NEW_SUB_TITLE/g, webUrlValue)
        .replace(/NEW_TYPE/g, attachTypeValue);

      target.innerHTML = content;

      const icons = target.querySelectorAll('div[data-type]');
      icons.forEach((icon) => {
        if (icon.dataset.type === attachTypeValue.toLowerCase()) {
          icon.classList.remove('tw-hidden');
        } else {
          icon.classList.add('tw-hidden');
        }
      });
    } catch (error) {
      console.error('Erro ao invocar a ação de edição:', error.message);
    }
  }
}
