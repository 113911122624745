import { Controller as BaseController } from '@hotwired/stimulus';

const defaultProps = {
  lang: 'pt-BR',
  inheritPlaceholder: true,
  dialogsInBody: true,
  lineHeights: ['0.2', '0.3', '0.4', '0.5', '0.6', '0.8', '1.0', '1.2', '1.4', '1.5', '2.0', '3.0'],
  toolbar: [
    ['style', ['style']],
    ['fontname', ['fontname']],
    ['fontsize', ['fontsize']],
    ['fontStyle', ['bold', 'underline', 'color', 'clear']],
    ['para', ['height', 'ul', 'ol', 'paragraph']],
    ['table', ['table']],
    ['insert', ['link', 'picture']],
    ['view', ['fullscreen', 'codeview', 'help']],
    ['extra', ['math']],
  ],
};

export default class Controller extends BaseController {
  static targets = ['summernote'];

  connect() {
    $(this.summernoteTarget).summernote({
      ...defaultProps,
      height: 300,
      callbacks: {
        onImageUpload: (files) => {
          // TODO: When refactor summernote.js bring this function to here
          window.summernoteSendFile(files[0], $(this));
        },
        onMediaDelete: (target) => {
          const uploadID = target['0'].id.split('-').slice(-1)[0];
          if (uploadID) {
            // TODO: When refactor summernote.js bring this function to here
            window.summernoteDeleteFile(uploadID);
          }
          target.remove();
        },
      },
    });
  }
}
