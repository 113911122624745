import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['alternatives', 'student', 'sidebar'];
  static values = { count: Number };

  connect() {
    this.CHANGES_TO_UPDATE = 10;
    this.requestQueue = [];
    this.sendingRequest = false;
    this.requestPath = '';

    super.connect();
  }

  updateResponses() {
    if (this.requestQueue.length > 0 && !this.sendingRequest) {
      // Ajax Call
      const data = this.requestQueue;
      this.requestQueue = [];
      this.sendingRequest = true;
      $.ajax({
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        type: 'patch',
        dataType: 'json',
        url: this.requestPath,
        data: { responses: data },
        complete: () => {
          this.sendingRequest = false;
          if (this.requestQueue.length >= this.CHANGES_TO_UPDATE) {
            this.updateResponses();
          }
        },
        error: (e) => {
          location.reload();
          alert(`Error ao salvar resposta: ${e.responseJSON.error.message}`);
        },
      });
    }
  }

  updateScores(event) {
    const hideClass = 'd-none';
    const loadingElement = document.getElementById('loading-response');
    if (loadingElement) {
      loadingElement.classList.remove(hideClass);
    }

    this.requestPath = this.alternativesTarget.dataset.way;

    const responseAttrs = {
      student_id: document.getElementById(event.params.number).value,
      exams_question_id: document.getElementById(event.params.question).value,
      alternative: event.target.value,
    };

    // Check if the user changes the response of other student
    if (this.requestQueue.length !== 0) {
      const studentChanged =
        this.requestQueue[this.requestQueue.length - 1].student_id !== responseAttrs.student_id;
      if (studentChanged) {
        this.updateResponses();
      }
    }

    this.requestQueue.push(responseAttrs);

    if (this.requestQueue.length >= this.CHANGES_TO_UPDATE) {
      this.updateResponses();
    }
  }

  toggleSidebarResponse() {
    const children = this.sidebarTarget.children;
    for (let i = 0; i < children.length; i++) {
      children[i].style.transition = 'opacity 0.25s ease-in-out';
    }

    const isMediumScreen = window.matchMedia('(min-width: 768px)').matches;

    const openWidth = isMediumScreen ? '550px' : '100vw';

    if (!isMediumScreen) {
      this.sidebarTarget.classList.add('tw-z-20');
      this.sidebarTarget.classList.add('tw-absolute');
    }

    if (this.sidebarTarget.style.width === '0px' || this.sidebarTarget.style.width === '') {
      this.sidebarTarget.style.width = openWidth;
      this.sidebarTarget.style.padding = '32px';

      setTimeout(() => {
        for (let i = 0; i < children.length; i++) {
          children[i].style.opacity = '1';
        }
      }, 300);
    } else {
      this.sidebarTarget.style.width = '0px';
      this.sidebarTarget.style.padding = '0px';
      for (let i = 0; i < children.length; i++) {
        children[i].style.opacity = '0';
      }
    }
  }
}
