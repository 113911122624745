import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['alternativeResponse', 'discursiveResponse'];

  sendUpdateRequest(params, path) {
    $.ajax({
      beforeSend: (xhr) => {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      url: path,
      type: 'patch',
      data: params,
      dataType: 'application/json',
      error: (httpResponse) => {
        alert(`Erro ao atualizar resposta: ${httpResponse.status}`);
      },
    });
  }

  updateAlternativeResponse(event) {
    const selectedAlternative = event.target.value;
    const updatePath = event.params.url;
    const responseId = event.params.id;
    const params = {
      scheduled_exams_response: {
        alternative_responses_attributes: {
          id: responseId,
          alternative: selectedAlternative,
        },
      },
    };
    this.sendUpdateRequest(params, updatePath);
  }

  updateDiscursiveResponse(event) {
    const responseText = event.target.value;
    const responseId = event.target.dataset.responseId;
    const updatePath = event.target.dataset.updatePath;
    const params = {
      scheduled_exams_response: {
        discursive_responses_attributes: {
          id: responseId,
          response: responseText,
        },
      },
    };
    this.sendUpdateRequest(params, updatePath);
  }
}
