import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static targets = ['circle', 'time'];
  static values = { start: String, total: Number };

  connect() {
    this.timeTarget.textContent = '...';

    this.interval = setInterval(() => {
      const startTime = new Date(this.startValue);
      const currentTime = new Date();
      const diffInMinutes = Math.floor((currentTime - startTime) / 60000);
      const newValue = this.calculateValue(diffInMinutes, this.totalValue);

      this.updateStrokeDasharray(newValue);

      const time = this.newTime(startTime, currentTime, this.totalValue);
      this.timeTarget.textContent = time;
    }, 1000);
  }

  newTime(startTime, currentTime, total) {
    const differenceInSeconds = total * 60 - Math.floor((currentTime - startTime) / 1000);

    const hours = Math.floor(differenceInSeconds / 3600);
    const minutes = Math.floor((differenceInSeconds % 3600) / 60);
    const seconds = differenceInSeconds % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  }

  disconnect() {
    clearInterval(this.interval);
  }

  updateStrokeDasharray(newValue) {
    this.circleTarget.style.strokeDashoffset = newValue;
  }

  calculateValue(mm, total) {
    return Math.ceil(146 - (146 / total) * mm);
  }
}
