import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  toggleStudentIds() {
    const hide = 'tw-hidden';

    const element = $('#students-select')[0];

    const selectValue = $('#exam-target select option:selected')[0].value ?? 'Todos os alunos';

    if (selectValue == 'Alunos específicos') {
      element.classList.remove(hide);
    } else {
      element.classList.add(hide);
    }
  }
}
