import { Controller as BaseController } from '@hotwired/stimulus';

export default class extends BaseController {
  static targets = ['modal'];

  open() {
    this.modalTarget.showModal();
  }

  close() {
    this.modalTarget.close();
  }

  closeAllModalsInScope() {
    const childModals = document.querySelectorAll('dialog');

    childModals.forEach((childModal) => {
      if (childModal.open) {
        childModal.close();
      }
    });
  }

  clickOutside(event) {
    if (event.target === this.modalTarget) {
      this.close(event);
    }
  }
}
