// app/javascript/controllers/accordion_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'icon'];

  connect() {
    this.isExpanded = false;
    this.updateHeight();
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
    this.updateHeight();
  }

  updateHeight() {
    if (this.isExpanded) {
      this.contentTarget.style.height = 'auto';
      this.contentTarget.style.paddingTop = '32px';
      this.iconTarget.style.transform = 'rotate(180deg)';
    } else {
      this.contentTarget.style.height = '0px';
      this.contentTarget.style.paddingTop = '0px';
      this.iconTarget.style.transform = 'rotate(0deg)';
    }
  }
}
