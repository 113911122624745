import { Controller as BaseController } from '@hotwired/stimulus';

export default class extends BaseController {
  static targets = ['content'];

  connect() {
    this.boundHide = this.hide.bind(this);
  }

  toggle() {
    this.contentTarget.classList.toggle('tw-hidden');
    if (!this.contentTarget.classList.contains('tw-hidden')) {
      document.addEventListener('click', this.boundHide);
    } else {
      document.removeEventListener('click', this.boundHide);
    }
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      this.contentTarget.classList.add('tw-hidden');
      document.removeEventListener('click', this.boundHide);
    }
  }
}
