import { Controller as BaseController } from '@hotwired/stimulus';

export default class Controller extends BaseController {
  static targets = ['source'];

  copy() {
    const cleanText = this.sourceTarget.textContent.trim().replace(/\s+/g, ' ');

    navigator.clipboard
      .writeText(cleanText)
      .then(() => {
        window.alert('Copiado com sucesso!');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  }
}
